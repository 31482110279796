export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const FILE_URL = process.env.REACT_APP_FILE_URL;
export const FILE_URL_CONTAINER = FILE_URL + '/cover/download';
export const APP_AUTH = process.env.REACT_APP_AUTH;
export const APP_EMAIL = 'info@producthired.com';
export const APP_PROMO_EMAIL = 'requestpromo@producthired.com';

export const GOOGLE_AUTH = APP_AUTH + '/google';
export const FACEBOOK_AUTH = APP_AUTH + '/facebook';
export const GOOGLE_CALENDAR_AUTH = APP_AUTH + '/google-calendar-primary';
export const GOOGLE_SECONDARY_CALENDAR_AUTH =
  APP_AUTH + '/google-calendar-secondary';
export const OUTLOOK_CALENDAR_AUTH = APP_AUTH + '/outlook-primary';
export const OUTLOOK_SECONDARY_CALENDAR_AUTH = APP_AUTH + '/outlook-secondary';
export const PAYPAL_AUTH = APP_AUTH + '/paypal';

export const STRIPEID = process.env.REACT_APP_STRIPE;

export const LOGIN = '/auth/login';
export const SIGNUP = '/auth/signup';
export const CONFIRM = '/auth/confirm';
export const CONFIRM_EMAIL_CHANGE = '/auth/confirmEmailChange';
export const NEW_PASSWORD = '/auth/newpassword';
export const USER_CONNECT = '/auth/connect';
export const TERMS = '/terms';
export const PRIVACY_POLICY = '/privacy-policy';
export const COACHING_TERMS = '/coaching-terms';
export const COACHING_CLIENT_TERMS = '/coaching-client-terms';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const SLACK_BOT_PAGE = '/slack-bot';
export const PAGE404 = '/404';
export const CONFIRM_COACH_GOOGLE_CALENDAR = '/auth/gcalendar';

export const GUIDES_HOME = '/guides';
export const GUIDESID = '/guides/:id';
export const GUIDES_REQUEST_URL =
  'https://hooks.zapier.com/hooks/catch/6037699/odupfpt/';

export const HOME = '/';

export const JOBSHOME = '/all-jobs';
export const JOBS = '/jobs';
export const JOBSID = '/jobs/:id';

export const EVENTSHOME = '/all-events';
export const EVENTS = '/events';
export const EVENTSID = '/events/:id';

export const EMPLOYER_HOME = '/employer';
export const EMPLOYER_DASHBOARD = '/employer/dashboard';

export const EMPLOYER_POSTAJOB = '/employer/post-a-job';
export const EMPLOYER_EDITAJOB = '/employer/edit-a-job';
export const EMPLOYER_EDITAJOBID = '/employer/edit-a-job/:id';

export const EMPLOYER_POSTEVENT = '/employer/post-a-event';
export const EMPLOYER_EDITEVENT = '/employer/edit-a-event';
export const EMPLOYER_EDITEVENTBYID = '/employer/edit-a-event/:id';

export const USER_PROFILE_MAIN = '/profile';
export const USER_PROFILE_NOTIFICATIONS = USER_PROFILE_MAIN + '/notifications';
export const USER_PROFILE_SEARCH_ALARMS = USER_PROFILE_MAIN + '/search-alarms';
export const USER_PROFILE_SAVED_JOBS = USER_PROFILE_MAIN + '/saved-jobs';
export const USER_PROFILE_APPLIED_JOBS = USER_PROFILE_MAIN + '/applied-jobs';
export const USER_PROFILE_COACHING = USER_PROFILE_MAIN + '/coaching';
export const USER_PROFILE_COACHING_PAYOUTS = USER_PROFILE_COACHING + '/payouts';
export const USER_PROFILE_COACHING_REVIEWS = USER_PROFILE_COACHING + '/reviews';

export const FACEBOOK = 'https://www.facebook.com/producthired/';
export const LINKEDIN = 'https://www.linkedin.com/company/producthired/';
export const TWITTER = 'https://twitter.com/ProductHired';

export const PRODUCTHIRED_JOB_CLUB = 'https://www.meetup.com/Product-Job-Club/';
export const PRODUCTHIRED_YOUTUBE = 'https://www.youtube.com/c/ProductHired/';

export const FROMHOME = 'HOME';
export const FROMCOACHESHOME = 'COACHES_HOME';
export const FROMJOBS = 'JOBS';
export const FROMJOBSHOME = 'JOBSHOME';
export const FROMEVENTSHOME = 'EVENTSHOME';
export const FROMEVENTS = 'EVENTS';
export const FROMSAVEDJOBS = 'USER_PROFILE_SAVED_JOBS';
export const FROMAPPLIEDJOBS = 'USER_PROFILE_APPLIED_JOBS';
export const FROMDASHBOARD = 'EMPLOYER_DASHBOARD';
export const PASSWORD_RESTORE = 'PASSWORD_RESTORE';
export const FROMNOTIFICATIONS = 'USER_PROFILE_NOTIFICATIONS';
export const FROMCOACHES = 'COACHES';
export const FROMCOACHING = 'COACHING';
export const FROMPROFILECOACHING = 'USER_PROFILE_COACHING';

export const MAPBOX_STYLES_HREF =
  'https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css';

export const COACHES_HOME = '/be-a-coach';
export const COACHING = '/coaching';
export const COACHES = '/coaches';
export const COACHESID = '/coaches/:id';
export const COACHING_SETUP = '/coaching/setup';
export const COACHING_EDIT = '/coaching/edit';

// INTERVIEW GUIDES PATHS
export const PRODUCTHIRED_GUIDE = `producthired-product-manager-interview-study-guide`;
export const GOOGLE_GUIDE = `google-product-manager-interview-study-guide`;
export const FACEBOOK_GUIDE = `facebook-product-manager-interview-study-guide`;
export const AMAZON_GUIDE = `amazon-product-manager-interview-study-guide`;
export const UBER_GUIDE = `uber-product-manager-interview-study-guide`;
export const MICROSOFT_GUIDE = `microsoft-product-manager-interview-study-guide`;
export const LYFT_GUIDE = `lyft-product-manager-interview-study-guide`;

// INTERVIEW GUIDES LINKS
export const PRODUCTHIRED_GUIDE_LINK = `${GUIDES_HOME}/${PRODUCTHIRED_GUIDE}`;
export const GOOGLE_GUIDE_LINK = `${GUIDES_HOME}/${GOOGLE_GUIDE}`;
export const FACEBOOK_GUIDE_LINK = `${GUIDES_HOME}/${FACEBOOK_GUIDE}`;
export const AMAZON_GUIDE_LINK = `${GUIDES_HOME}/${AMAZON_GUIDE}`;
export const UBER_GUIDE_LINK = `${GUIDES_HOME}/${UBER_GUIDE}`;
export const MICROSOFT_GUIDE_LINK = `${GUIDES_HOME}/${MICROSOFT_GUIDE}`;
export const LYFT_GUIDE_LINK = `${GUIDES_HOME}/${LYFT_GUIDE}`;

// JOBS REMOTE LINKS
export const JOBS_LINK_REMOTE_PM = `${JOBS}?roles=product-manager&locations=remote-online`;
export const JOBS_LINK_REMOTE_PMM = `${JOBS}?roles=product-marketing-manager&locations=remote-online`;
export const JOBS_LINK_REMOTE_PD = `${JOBS}?roles=product-design&locations=remote-online`;
export const JOBS_LINK_REMOTE_UXR = `${JOBS}?roles=ux-researcher&locations=remote-online`;
export const JOBS_LINK_REMOTE_DS = `${JOBS}?roles=data-science&locations=remote-online`;

// JOBS BY LOCATIONS LINKS
export const JOBS_LINK_LOCATION_PM_AUSTIN = `${JOBS}?roles=product-manager&locations=austin-tx`;
export const JOBS_LINK_LOCATION_PM_SF = `${JOBS}?roles=product-manager&locations=san-francisco-ca`;
export const JOBS_LINK_LOCATION_PM_NY = `${JOBS}?roles=product-manager&locations=new-york-city-ny`;
export const JOBS_LINK_LOCATION_UXD_AUSTIN = `${JOBS}?roles=product-design&locations=austin-tx`;
export const JOBS_LINK_LOCATION_UXD_SF = `${JOBS}?roles=product-design&locations=san-francisco-ca`;
export const JOBS_LINK_LOCATION_UXD_NY = `${JOBS}?roles=product-design&locations=new-york-city-ny`;

// JOBS BY ROLE LINKS
export const JOBS_LINK_ROLE_PM = `${JOBS}?roles=product-manager`;
export const JOBS_LINK_ROLE_PMM = `${JOBS}?roles=product-marketing-manager`;
export const JOBS_LINK_ROLE_PD = `${JOBS}?roles=product-design`;
export const JOBS_LINK_ROLE_UXR = `${JOBS}?roles=ux-researcher`;
export const JOBS_LINK_ROLE_DS = `${JOBS}?roles=data-science`;

// COACHES BY ROLE LINKS
export const COACHES_LINK_ROLE_PM = `${COACHES}?roles=product-manager`;
export const COACHES_LINK_ROLE_PMM = `${COACHES}?roles=product-marketing-manager`;
export const COACHES_LINK_ROLE_PD = `${COACHES}?roles=product-design`;
export const COACHES_LINK_ROLE_UXR = `${COACHES}?roles=ux-researcher`;
export const COACHES_LINK_ROLE_PRM = `${COACHES}?roles=project-program`;
export const COACHES_LINK_ROLE_DS = `${COACHES}?roles=data-science`;

// COACHES BY SERVICE TYPE LINKS
export const COACHES_LINK_SERVICE_TYPE_PR = `${COACHES}?types=portfolio-review`;
export const COACHES_LINK_SERVICE_TYPE_RR = `${COACHES}?types=resume-review`;
export const COACHES_LINK_SERVICE_TYPE_CC = `${COACHES}?types=career-coaching`;
export const COACHES_LINK_SERVICE_TYPE_PREP = `${COACHES}?types=interview-prep`;
